<template>
  <div>
    <tours-layout>
      <div class="container">
        <div class="row">
          <div class="col-md-7 padding-top-80">
            <h1>Paragliding</h1>

            <div class="w-100 text-center image-wrapper">
              <!-- <img class="w-100 img-fluid img-thumbnail" src="https://cms.flexicms.tech/images/cms/mobile/1606843066.jpeg" alt=""> -->
            </div>

            <p>
              <strong>
                Paragliding in Ohrid - City Flight <br />
                <br />

                Take off at a 1300 M ASL from mountain Galichica to witness a
                spectacular view of the UNESCO-listed City of Ohrid and Lake
                Ohrid. <br />
                <br />

                The complete duration of the activity is approx. 2 hours, flight
                time lasts from 10-15 depending on flying conditions. <br />
                <br />

                This offer includes:

                <ul>
                  <li>- transport to and from accommodation</li>
                  <li>- National Park entrance fee</li>
                  <li>- Tandem flight with licensed pilots</li>
                  <li>- Souvenir gift</li>
                  <li>- Unforgettable experience</li>
                </ul>

                This activity is available from late April till early October.
                <br /><br />
              </strong>
            </p>

            <p>
              City Flight <br /><br />

              Take off at a 1300 M ASL from mountain Galichica to witness a
              spectacular view of the UNESCO-listed City of Ohrid and Lake
              Ohrid. <br /><br />

              This Paragliding Ohrid adventure starts by picking you up from
              your accommodation and transporting you to the take-off place. The
              transportation is an adventure by itself, very similar to an
              off-road experience in the mountains. <br /><br />

              At the take-off point, we will have a ten-minute briefing, our
              professional tandem pilot will take you into the air for a flight
              that lasts from 10 – 15 minutes depending on flying conditions.
              <br /><br />

              The passenger (you) and the flight instructor each have their own
              comfortable harness. You are then connected together and finally
              connected to the glider. Once in the air your flight instructor
              will explain to you what he is doing and how paragliders fly. If
              conditions permit, you may be offered hands-on control of the
              glider. <br /><br />

              The landing place is at Cuba Libre Beach, where after landing you
              can relax with a coffee or a beer, see the photos or video from
              the flight and share your impressions. <br /><br />

              The complete duration of the activity is approx. 2 hours.
              <br /><br />

              Guided by instructors with over 20 years of experience, this
              activity is available for beginners of every age.
            </p>

            <!-- <div class="text-center">
                                <a href="#about" class="btn-green scrool">Explore Benefits</a>
                            </div> -->
          </div>

          <div class="col-md-5 form-wrapper">
            <form ref="form" @submit.prevent="sendEmail">
              <div class="book-title">Book Now</div>

              <input type="hidden" name="activity" value="Paragliding" />

              <!-- Name -->
              <div class="form-group">
                <label for="exampleInputEmail1">Name</label>
                <input
                  type="text"
                  name="name"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  required
                  v-model="form.name"
                />
              </div>

              <!-- Name -->
              <div class="form-group">
                <label for="exampleInputEmail1">Number of reservations</label>
                <input
                  type="number"
                  name="reservations_number"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="1"
                  required
                  v-model="form.reservations_number"
                />
              </div>

              <!-- Contact -->
              <div>
                <div class="text-center py-10">Contact</div>

                <div class="contact-type">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input mr-5 cursor-pointer"
                      type="radio"
                      id="contactEmail"
                      value="email"
                      v-model="contactType"
                    />
                    <label
                      class="form-check-label cursor-pointer"
                      for="contactEmail"
                    >
                      Email
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input mr-5 cursor-pointer"
                      type="radio"
                      id="contactViber"
                      value="viber"
                      v-model="contactType"
                    />
                    <label
                      class="form-check-label cursor-pointer"
                      for="contactViber"
                    >
                      Viber
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input mr-5 cursor-pointer"
                      type="radio"
                      id="contactWhatsup"
                      value="whatsup"
                      v-model="contactType"
                    />
                    <label
                      class="form-check-label cursor-pointer"
                      for="contactWhatsup"
                    >
                      Whatsup
                    </label>
                  </div>
                </div>
              </div>

              <!-- Email -->
              <div v-if="contactType === 'email'" class="form-group">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  class="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  v-model="form.email"
                  required
                />
              </div>

              <!-- Viber -->
              <div v-if="contactType === 'viber'" class="form-group">
                <label>Viber</label>
                <input
                  type="text"
                  name="viber"
                  class="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Enter Viber"
                  v-model="form.viber"
                  required
                />
              </div>

              <!-- Whatsup -->
              <div v-if="contactType === 'whatsup'" class="form-group">
                <label>Whatsup</label>
                <input
                  type="text"
                  name="whatsup"
                  class="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Enter Whatsup"
                  v-model="form.whatsup"
                  required
                />
              </div>

              <label>Date</label>
              <date-picker
                format="DD/MM/YYYY"
                v-model="form.date"
                style="width: 100%; padding-bottom: 10px"
                valueType="format"
                name="date"
                :disabled-date="disabledDates"
              ></date-picker>

              <div class="form-group">
                <label for="exampleInputPassword1">Additional Comment</label>
                <textarea
                  type="password"
                  name="message"
                  style="width: 100%; padding-bottom: 10px"
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Additional Comment"
                  v-model="form.message"
                ></textarea>
              </div>

              <button type="submit" class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
        <!--end row -->
      </div>
    </tours-layout>
  </div>
</template>

<script>
import ToursLayout from "@/components/ToursLayout.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import emailjs from "@emailjs/browser";
import { mapActions } from "vuex";

export default {
  components: {
    ToursLayout,
    DatePicker,
  },

  data() {
    return {
      contactType: "email",

      form: {
        name: "",
        email: "",
        viber: "",
        whatsup: "",
        date: moment().format("DD/MM/YYYY"),
        reservations_number: 1,
        message: ""
      },

      // disabledDates: new Date(2023, 0, 3)
    };
  },

  methods: {
    ...mapActions(["setProcessing"]),

    disabledDates: function (date) {
      return date < new Date(2023, 0, 3);
    },

    sendEmail() {
      this.setProcessing(true);

      emailjs
        .sendForm(
          "service_ombftjk",
          "template_5igtuyk",
          this.$refs.form,
          "l-FVjgl3UiypkcD_s"
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
            this.setProcessing(false);
            this.resetForm()
            this.$swal(
              "Good job!",
              "We will contact You as soon as possible!",
              "success"
            );
          },
          (error) => {
            console.log("FAILED...", error.text);
            this.setProcessing(false);
          }
        );
    },

    resetForm() {
        this.form.name = "",
        this.form.email = "",
        this.form.viber = "",
        this.form.whatsup = "",
        this.form.date = moment().format("DD/MM/YYYY"),
        this.form.reservations_number = 1,
        this.form.message = ""
    }
  },
};
</script>

<style scoped>
.image-wrapper {
  min-height: 300px;
  background: url("https://cms.flexicms.tech/images/cms/mobile/1605870799.png");
  background-repeat: no-repeat;
  background-position: center center;
  /* background-attachment: fixed; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  border-radius: 4px;
  margin-bottom: 3rem;
}

.form-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-type {
  display: flex;
  justify-content: space-around;
}

.mr-5 {
  margin-right: 5px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.book-title {
  padding-bottom: 4rem;
  font-size: 3rem;
  text-align: center;
}
</style>
